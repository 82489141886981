@import '../styles/typography.scss';
@import '../styles/colors.scss';
@import '../styles/mixins.scss';

.my-custom-view-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid rgb(212, 212, 212);
  padding: 0.6em;
  outline: none;
  //width: 300px;
}