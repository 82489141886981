@import "../styles/typography.scss";
@import "../styles/colors.scss";
@import "../styles/mixins.scss";

/*
*/

.side-bar-big {
  top: 2.5rem;
  left: 0px;
  color: rgb(255, 255, 255);
  width: 9rem;
  height: 100vh;
  z-index: 111;
  overflow: hidden;
  overflow-y: scroll;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px;
  transition: all 250ms ease-in-out 0s;
  background-size: cover;
  background-color: $metaBlue;
  background-repeat: no-repeat;
  background-position: center top;  
  .rrs {
    width: 8.5rem;
    margin-left: 0.7rem;
    font-size: 0.8rem;
  }
  &__list_heading {
    margin: 0.35rem !important;
  }
  &__study-title {
    font-size: $font-size-tiny;
    text-transform: uppercase;
    font-size: 0.6rem;
    text-transform: uppercase;
    text-align: center;
    padding-top: 2rem;
  }
  &__study-title-wraper {
    margin-top: 2rem;
    color: $dark;
    height: 3rem;
    position: relative;
  }

  &__bg {
    width: 4.5rem;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    background: white;
    box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
  }
  &__logo {
    position: relative;
    width: 2.5rem;
    left: 1rem;
    margin-top: 1.5rem;
    filter: grayscale(100);
    z-index: 2;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      filter: hue-rotate(-55deg);
      
    }
  }
  &__list {
    color: white;
    text-align: center;
    margin-top: 5.6rem;
    position: relative;
    z-index: 2;
    &:before {
      content: "";
      background: grey;
      opacity: 0.25;
      width: 3.5rem;
      height: 1px;
      position: absolute;
      top: -4.5rem;
      left: 0.5rem;
      z-index: 1;
    }
  }

  &__list-item {
    min-height: 2rem;
    position: relative;
    width: 100%;
    &--studies {
      padding-top: 0.2rem;
      width: 4.5rem;
    }
    &--selected {
      
      cursor: white;
      background: #333;
      a {
        color: white;
        cursor: default;

        span {
          color: white;
        }
        svg {
          color: white;
        }
      }
    }
  }
 .selected {
  background-color: rgba(0, 0, 0, 0.05);
 }
  .file-upload-icon {
    width: 1rem;
    height: 1rem;
    stroke: grey;
    stroke: none;
    fill: black;
    color: grey;
  }
  &__studyMenuWrapper {
    position: absolute;
    top: 0;
    background: #333;
    width: 10.5rem;
    left: 4.5rem;
    height: 100%;
    box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
    animation: menu-fade-in 180ms ease-out forwards;
  }
  
  @keyframes menu-fade-in {
    0% {
        transform: translate3d(-14rem, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
  }
}

.side-bar-small {
  top: 2.5rem;
  left: 0px;
  color: rgb(255, 255, 255);
  width: 3rem;
  height: 100vh;
  z-index: 111;
  overflow: hidden;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px;
  transition: all 250ms ease-in-out 0s;
  background-size: cover;
  background-color: $metaBlue;
  background-repeat: no-repeat;
  background-position: center top;
  /*
  width: 4.5rem;
  height: 100%;
  position: fixed;
  background: #333;
  background: white;
  font-family: $font-family;
  top: 0;
  z-index: 11;
  //box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 7px 7px 0px rgba(0, 0, 0, 0.5);
  //box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
  */
  .rrs {
    width: 8.5rem;
    margin-left: 0.7rem;
    font-size: 0.8rem;
  }
  &__study-title {
    font-size: $font-size-tiny;
    text-transform: uppercase;
    font-size: 0.6rem;
    text-transform: uppercase;
    text-align: center;
    padding-top: 2rem;
  }
  &__study-title-wraper {
    margin-top: 2rem;
    color: $dark;
    height: 3rem;
    position: relative;
  }

  &__bg {
    width: 4.5rem;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    background: white;
    box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
  }
  &__logo {
    position: relative;
    width: 2.5rem;
    left: 1rem;
    margin-top: 1.5rem;
    filter: grayscale(100);
    z-index: 2;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      filter: hue-rotate(-55deg);
      
    }
  }
  &__list {
    color: white;
    text-align: center;
    margin-top: 5.6rem;
    position: relative;
    z-index: 2;
    &:before {
      content: "";
      background: grey;
      opacity: 0.25;
      width: 3.5rem;
      height: 1px;
      position: absolute;
      top: -4.5rem;
      left: 0.5rem;
      z-index: 1;
    }
  }

  &__list-item {
    min-height: 2rem;
    position: relative;
    width: 100%;
    &--studies {
      padding-top: 0.2rem;
      width: 4.5rem;
    }
    &--selected {
      cursor: white;
      background: #333;
      a {
        color: white;
        cursor: default;

        span {
          color: white;
        }
        svg {
          color: white;
        }
      }
    }
  }

  .file-upload-icon {
    width: 1rem;
    height: 1rem;
    stroke: grey;
    stroke: none;
    fill: black;
    color: grey;
  }
  &__studyMenuWrapper {
    position: absolute;
    top: 0;
    background: #333;
    width: 10.5rem;
    left: 4.5rem;
    height: 100%;
    box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
    animation: menu-fade-in 180ms ease-out forwards;
  }
  
  @keyframes menu-fade-in {
    0% {
        transform: translate3d(-14rem, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
  }
}
