@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

/*
usage 
div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}

*/

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin clearfix-micro() {

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

$iPhone5: '(max-width: 320px)';
$iPhone: '(min-width: 400px)';
$tablet: '(min-width: 768px)';
$desktop: '(min-width: 1250px)';

@mixin tablet {
    @media #{$tablet} {
        @content;
    }
}

@mixin iPhone {
    @media #{$iPhone} {
        @content;
    }
}

@mixin iPhone5 {
    @media #{$iPhone5} {
        @content;
    }
}

@mixin desktop {
    @media #{$desktop} {
        @content;
    }
}