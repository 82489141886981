@import "../styles/colors.scss";
.bottom-bar {
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    height: 2rem;
    color: "#ffffff";
    background: $metaDarkBlue;
    z-index: 1111;
    /*box-shadow: 0px 0px 6px rgba(173, 173, 173, 0.40);*/
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042), 0px 3px 14px 2px rgba(0, 0, 0, 0.036);

    padding: 0.65rem;
    list-style: none;
    p {
        color: #f5f5f5;
    }
}


