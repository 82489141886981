.pointing-arrow{
    position: absolute;
    top: 50%;
    /*left: 50%;*/
    transform: translate(-50%,-50%);
    display: table;

    span{
        display:table-cell;
        width: 12px;
        height: 12px;
        border-bottom: 3px solid red;
        border-right: 3px solid red;
        animation: animate 2s infinite;
    }
    span:nth-child(2){
        animation-delay: -0.2s;
    }
    span:nth-child(3){
        animation-delay: -0.4s;
    }
}

@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(-45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(-45deg);
    }
}