@import "../styles/mixins.scss";
.app {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  &--fullsize {
    height: 100%;
    position: absolute;
    overflow-x: auto;
  }
  &--noscroll {
    overflow-y: hidden;
  }
  &--no-xscroll {
    overflow-x: hidden;
  }

  /* @include desktop {
     overflow-y: hidden;
  }*/

}
