/*

  React Responsive Select - Default styles

*/

.rrs {
    position: relative;
    box-sizing: border-box;
  }
  
  .rrs *,
  .rrs *:before,
  .rrs *:after {
    box-sizing: border-box;
  }
  
  .rrs__button {
    color: #555;
    position: relative;
    cursor: pointer;
    height: 44px;
    line-height: 44px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .rrs__button--disabled {
    color: #999999;
    background: #f5f5f5;
    cursor: default;
  }
  
  .rrs__button:focus {
    outline: 0;
  }
  
  .rrs__button + .rrs__options {
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    z-index: 2;
    border: 1px solid #999;
    border-top: 1px solid #eee;
    border-radius: 0 0 2px 2px;
    top: 44px;
    width: 100%;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
  
  .rrs--options-visible .rrs__options {
    height: auto;
    visibility: visible;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 230px;
  }
  
  .rrs__option {
    cursor: pointer;
    padding: 0.75rem 1rem;
    margin: 0;
  }
  
  .rrs__option * {
    pointer-events: none;
  }
  
  .rrs__option:focus {
    outline: 1px solid #e0e0e0;
  }
  
  .rrs__option:hover {
    background: #f5f5f5;
    color: #0273b5;
  }
  
  .rrs__option:active {
    background: #e1f5fe;
  }
  
  .rrs__option.rrs__option--next-selection {
    background: #f1f8fb;
    color: #0273b5;
  }
  
  .rrs__option.rrs__option--selected {
    color: #0273b5;
  }
  
  .rrs__option.rrs__option--disabled {
    color: #999999;
    background: #f5f5f5;
    cursor: default;
  }
  
  .rrs__option.rrs__option--header {
    color: #666666;
    cursor: default;
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    background: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .rrs__label {
    padding: 0 2rem 0 1rem;
    display: inline-flex;
    width: 100%;
    max-width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: inherit;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .rrs--options-visible .rrs__label,
  .rrs__button:focus .rrs__label {
    border-bottom: 1px solid #fff;
    outline: 0;
    border: 1px solid #999;
  }
  
  .rrs--has-changed .rrs__label {
    color: #0273b5;
  }
  
  /*
  
    Multiselect overrides
  
  */
  
  .rrs__multiselect-label {
    display: inline-flex;
    max-width: 100%;
    line-height: 1;
  }
  
  .rrs__multiselect-label__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .rrs__multiselect-label__badge{
    border: 1px solid #ccc;
    padding: 1px 6px;
    margin: 0 0 0 4px;
    border-radius: 8px;
    color: #666;
    font-size: 11px;
    vertical-align: middle;
    display: inline-block;
  }
  
  /* 
  
    Checkbox
  
  */
  
  .rrs .checkbox {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    top: -1px;
    border: 1px solid #ccc;
    margin: 2px 8px 2px 0;
    border-radius: 4px;
  }
  
  .rrs__option.rrs__option--selected .checkbox {
    border: 1px solid #0273b5;
  }
  
  .rrs .checkbox-icon {
    fill: #fff;
    position: absolute;
    left: 2px;
    top: 2px;
  }
  
  .rrs__option.rrs__option--selected .checkbox-icon {
    fill: #0273b5;
  }
  
  
  /*
  
    Caret Icon
  
  */
  
  .rrs .caret-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    fill: #333;
  }
  
  .rrs--options-visible .caret-icon {
    transform: rotate(180deg);
  }