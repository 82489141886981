@import '../styles/typography.scss';
@import '../styles/colors.scss';
@import '../styles/mixins.scss';

.upload-files {
    font-family: $font-family;
    /*position: relative;
    top: 8rem;
    left: 10rem;*/


    .box {
        background-color: #dfc8ca;
        padding: 6.25rem 1.25rem;
    }
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile-1 + label {
        color: #f1e5e6;
        background-color: #d3394c;
    }
    .inputfile + label {
        max-width: 80%;
        font-size: 1.25rem;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        padding: 0.625rem 1.25rem;
    }
    
    &__dropzone {
        
        /*position: relative;*/
        background: transparent;
        //border: 2px dashed #80808026;
        border: 2px dashed lightblue;
        /*width: 21rem;
        left: 14rem;*/
        height: 16rem;
        /* top: -1rem; */
        width: 100%;
        
        input {
            &:focus {
                outline: 0!important;
            }
        }
        &:focus {
            outline: 0;
        }


        &--admin {
            top: -4rem;
        }

    }

    &__dropzone_disabled {
        /*position: relative;*/
        background: transparent;
        //border: 2px dashed #80808026;
        border: 2px dashed red;
        /*width: 21rem;
        left: 14rem;*/
        height: 16rem;
        /* top: -1rem; */
        width: 17rem;
        
        input {
            &:focus {
                outline: 0!important;
            }
        }
        &:focus {
            outline: 0;
        }


        &--admin {
            top: -4rem;
        }        
    }     
}


